var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"internal-letters"},[(!_vm.isLoaded)?_c('v-card',{staticClass:"text-center pa-10 cards"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('circle-4'),_c('h6',{staticClass:"mt-6"},[_vm._v("در حال بارگذاری ...")])],1)]):_c('div',[(_vm.$router.currentRoute.path == '/' + _vm.role + '/secretariat/internal')?_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"me-1",on:{"click":function($event){_vm.search = ''}}},[_c('v-icon',{staticClass:"px-2"},[_vm._v("$Inbox")]),_vm._v("نامه‌های دریافت شده")],1),_c('v-tab',{staticClass:"ms-1",on:{"click":function($event){_vm.search = ''}}},[_c('v-icon',{staticClass:"px-2"},[_vm._v("$Outbox")]),_vm._v("نامه‌های ارسال شده")],1)],1):_vm._e(),(_vm.$router.currentRoute.path == '/' + _vm.role + '/secretariat/internal')?_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticClass:"letter-card grey-card"},[(
              _vm.$router.currentRoute.path ==
              '/' + _vm.role + '/secretariat/internal'
            )?_c('Letters',{attrs:{"letters":_vm.letters,"type":'internal',"subtype":'received',"isLoaded":_vm.isLoaded,"notSeen":_vm.notSeen},on:{"save":_vm.save,"removeSave":_vm.removeSave,"archive":_vm.archive,"removeArchive":_vm.removeArchive,"viewLetter":_vm.viewLetter}}):_vm._e()],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"letter-card grey-card"},[(
              _vm.$router.currentRoute.path ==
              '/' + _vm.role + '/secretariat/internal'
            )?_c('Letters',{attrs:{"letters":_vm.letters,"type":'internal',"subtype":'sent',"isLoaded":_vm.isLoaded},on:{"save":_vm.save,"archive":_vm.archive,"viewLetter":_vm.viewLetter,"removeSave":_vm.removeSave}}):_vm._e()],1)],1)],1):_vm._e(),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }