<template>
  <div class="inbox-letters">
    <div class="header">
      <v-row
        class="white ms-0 me-0"
        :style="type == 'internal' ? 'border-radius: 0 0 17px 17px' : ''"
      >
        <v-col v-if="isLoaded">
          <div class="d-flex flex-row">
            <h4 class="pt-1" :class="type == 'internal' ? 'mb-4' : 'mb-0'">
              صندوق
              {{
                type == "inbox"
                  ? "ورودی"
                  : type == "outbox"
                  ? "خروجی"
                  : "سازمانی"
              }}
            </h4>
            <div
              v-if="type == 'inbox' && notSeen > 0"
              class="purple-badge ms-6 me-6"
            >
              <span> {{ notSeen }} نامه جدید </span>
            </div>
          </div>
          <div v-if="type == 'internal'" class="d-flex flex-row">
            <div style="font-size: 18px">
              نامه‌های {{ subtype == "received" ? "دریافت شده" : "ارسال شده" }}
            </div>
            <div
              v-if="subtype == 'received' && notSeen > 0"
              class="purple-badge ms-6 me-6"
            >
              <span> {{ notSeen }} نامه جدید </span>
            </div>
          </div>
        </v-col>
        <v-col class="pe-2">
          <div class="custom-input">
            <v-text-field
              class="float-end"
              outlined
              dense
              style="width: 330px"
              v-model="search"
              placeholder="شماره نامه را جستجو کنید..."
            >
              <template #prepend-inner>
                <v-icon> $Magnify </v-icon>
              </template>
              <template v-if="search" #append>
                <v-icon @click="search = ''"> $Close </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="main-container mt-9 pb-6">
      <v-card class="text-center pa-10 cards" v-if="!isLoaded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <circle-4></circle-4>
          <h6 class="mt-6">در حال بارگذاری ...</h6>
        </div>
      </v-card>
      <div v-else>
        <v-card class="cards" v-if="search && !computedLetters.length">
          <v-row>
            <v-col
              class="text-center text--grey text--darken-1"
              style="font-size: 15px"
            >
              <div class="border-box">
                <v-icon style="margin-left: 88px">$NoResult</v-icon>
                <p style="margin-top: 88px" class="mb-0">
                  نتیجه‌ای برای "{{ search }}" یافت نشد.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="cards" v-if="!search && !computedLetters.length">
          <v-row>
            <v-col
              class="text-center text--grey text--darken-1"
              style="font-size: 16px"
            >
              <div class="border-box">
                <v-icon style="margin-left: 88px">$NoLetter</v-icon>
                <p style="margin-top: 88px">
                  {{
                    subtype == "received"
                      ? "تاکنون نامه‌ای دریافت نشده است."
                      : "تاکنون نامه‌ای ثبت نشده است."
                  }}
                </p>
                <v-btn
                  class="blue-borderedBtn mt-6"
                  @click="goToNew()"
                  v-if="subtype != 'received'"
                  >ثبت نامه
                  {{
                    type == "inbox"
                      ? "ورودی"
                      : type == "outbox"
                      ? "خروجی"
                      : "سازمانی"
                  }}
                  جدید <v-icon class="ps-1">mdi-plus</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel
            v-for="(item, index) in computedLetters"
            :key="index"
            :class="
              type != 'outbox' && subtype != 'sent' && !item.seen
                ? type == 'inbox'
                  ? 'purple-border'
                  : 'blue-border'
                : ''
            "
          >
            <v-expansion-panel-header class="pe-6 ps-6 py-5 mb-0">
              <template v-slot:default="{ open }">
                <div class="d-flex flex-column mx-0">
                  <v-row>
                    <v-col cols="2" class="text-right pt-5">{{
                      item.letterNo
                    }}</v-col>
                    <v-col class="text-right pt-5">{{
                      item.title.length > 25
                        ? item.title.slice(0, 25) + "..."
                        : item.title
                    }}</v-col>
                    <v-col class="text-center pt-5">
                      <div v-if="subtype != 'sent' && type != 'outbox'">
                        {{
                          item.senderName.length > 30
                            ? item.senderName.slice(0, 30) + "..."
                            : item.senderName
                        }}
                      </div>
                      <div v-else>
                        {{
                          item.receiverName.length > 30
                            ? item.receiverName.slice(0, 30) + "..."
                            : item.receiverName
                        }}
                      </div>
                    </v-col>
                    <v-col class="text-left pt-5">{{
                      persianMonthName(item.date)
                    }}</v-col>
                    <v-col cols="2" class="text-left pt-4"
                      ><span
                        @click.stop="open = !open"
                        @click="
                          !item.marked
                            ? $emit('save', item.id)
                            : $emit('removeSave', item.id)
                        "
                      >
                        <v-icon
                          style="font-size: 28px"
                          v-if="!item.marked"
                          :class="type == 'inbox' ? 'purple-icon' : 'blue-icon'"
                          >$Save</v-icon
                        >
                        <v-icon
                          style="font-size: 28px"
                          v-else
                          :class="
                            type == 'inbox' ? 'purple-icon2' : 'blue-icon2'
                          "
                          >$Saved</v-icon
                        >
                      </span></v-col
                    >
                  </v-row>
                  <v-row v-if="!open"
                    ><v-col class="text-center py-0"
                      ><v-icon color="grey">mdi-dots-horizontal</v-icon></v-col
                    ></v-row
                  >
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <hr class="mt-0" />
              <v-row class="px-15">
                <v-col
                  v-if="item.responseArchiveNo != '-'"
                  class="text-right pt-6"
                >
                  <span class="grey--text text--darken-1"
                    >پاسخ به نامه شماره
                    <u style="text-decoration-color: #959ab1">{{
                      item.responseArchiveNo
                    }}</u></span
                  >
                </v-col>
                <v-col class="text-left">
                  <v-btn
                    @click="$emit('viewLetter', item.id)"
                    :class="
                      type == 'inbox'
                        ? 'purple-borderedBtn'
                        : 'blue-borderedBtn'
                    "
                    >مشاهده و ویرایش</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="type == 'inbox' ? item.abstract != '-' : item.text != '-'"
              >
                <v-col>
                  <span
                    v-html="type == 'inbox' ? item.abstract : item.text"
                  ></span>
                </v-col>
              </v-row>
              <hr class="mb-0" />
              <v-row v-if="item.tags.length" class="mt-6 mb-4">
                <v-col class="d-flex flex-row py-0">
                  <div
                    v-for="(tag, index) in item.tags"
                    :key="index"
                    class="me-2"
                    :class="type == 'inbox' ? 'purple-badge2' : 'blue-badge'"
                  >
                    <span>{{ tag }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="px-15 mt-2">
                <v-col>
                  <v-btn
                    @click="$emit('archive', item.id)"
                    :class="
                      type == 'inbox'
                        ? 'purple-borderedBtn'
                        : 'blue-borderedBtn'
                    "
                    >بایگانی نامه</v-btn
                  >
                </v-col>
                <v-col class="text-left">
                  <v-btn
                    v-if="subtype != 'sent'"
                    @click="reply(item.id)"
                    :class="
                      type == 'inbox'
                        ? 'purple-borderedBtn'
                        : 'blue-borderedBtn'
                    "
                    >پاسخ‌دهی</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                ><v-col class="text-center pb-0"
                  ><span @click="panel = null" class="pointer"
                    ><v-icon color="grey">mdi-dots-horizontal</v-icon></span
                  ></v-col
                ></v-row
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- <v-card
          v-for="(item, index) in computedLetters"
          :key="index"
          class="letter-card mt-6"
          :class="type == 'inbox' ? 'purple-border' : 'blue-border'"
        >
          <div @click="$emit('viewLetter', item.id)" class="pointer">
            <div class="grey--text text--darken-2">
              <v-row>
                <v-col>
                  <div>
                    <h5 class="mb-5">
                      <v-icon class="pe-3">$EditUser</v-icon> فرستنده:
                      {{ item.senderName }}
                    </h5>
                    <h5>
                      <v-icon class="pe-3">$TickUser</v-icon> گیرنده:
                      {{ item.receiverName }}
                    </h5>
                  </div>
                </v-col>
                <v-col class="d-flex flex-column justify-space-between">
                  <div class="d-flex flex-row justify-content-end">
                    <div
                      v-if="item.projectName != '-' && item.projectName != ''"
                      class="orange-badge ms-2"
                    >
                      {{ item.projectName }}
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-end">
                    <span>شماره نامه: {{ item.letterNo }}</span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <hr color="#ccc" />
            <div class="grey--text text--darken-2">
              <v-row>
                <v-col>
                  <span>موضوع نامه: {{ item.title }}</span>
                </v-col>
                <v-col v-if="item.responseArchiveNo != '-'" class="text-left">
                  <span
                    >پاسخ به نامه شماره
                    <u style="text-decoration-color: #959ab1">{{
                      item.responseArchiveNo
                    }}</u></span
                  >
                </v-col>
              </v-row>
              <div class="letter-borderBox mt-6">
                <span
                  v-html="type == 'inbox' ? item.abstract : item.text"
                ></span>
              </div>
              <v-row class="mt-6">
                <v-col class="d-flex flex-row">
                  <div
                    v-for="(tag, index) in item.tags"
                    :key="index"
                    class="me-2"
                    :class="type == 'inbox' ? 'purple-badge2' : 'blue-badge'"
                  >
                    {{ tag }}
                  </div>
                </v-col>
                <v-col class="text-left">
                  <p class="mb-0 mt-3">
                    تاریخ ارسال: <span dir="ltr">{{ item.date }}</span>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
          <hr class="mb-6 mt-4" />
          <div
            :class="type == 'inbox' ? 'purple-action' : 'blue-action'"
            class="d-flex flex-row justify-space-between"
          >
            <div class="d-flex flex-row">
              <v-btn
                @click="reply(item.id)"
                class="me-4"
                :class="type == 'inbox' ? 'purple-mainBtn' : 'blue-mainBtn'"
                height="44"
                ><v-icon class="pe-2">$Messages</v-icon>ارسال پاسخ</v-btn
              >
              <v-btn
                @click="
                  !item.marked
                    ? $emit('save', item.id)
                    : $emit('removeSave', item.id)
                "
                class="me-4"
                :class="
                  type == 'inbox'
                    ? !item.marked
                      ? 'purple-borderedBtn'
                      : 'purple-mainBtn'
                    : !item.marked
                    ? 'blue-borderedBtn'
                    : 'blue-mainBtn'
                "
                :disabled="isBusy"
                height="44"
                ><v-icon class="pe-2">$Save</v-icon>ذخیره</v-btn
              >
              <v-btn
                @click="
                  !item.archive
                    ? $emit('archive', item.id)
                    : $emit('removeArchive', item.id)
                "
                height="44"
                :class="
                  type == 'inbox'
                    ? !item.archive
                      ? 'purple-borderedBtn'
                      : 'purple-mainBtn'
                    : !item.archive
                    ? 'blue-borderedBtn'
                    : 'blue-mainBtn'
                "
                :disabled="isBusy"
                ><v-icon class="pe-2">$Archive</v-icon>ارسال به بایگانی</v-btn
              >
            </div>
            <div>
              <v-btn
                :class="
                  type == 'inbox'
                    ? 'purple-borderedBtn'
                    : 'blue-borderedBtn edit-btn'
                "
                @click="edit(item.id)"
                height="44"
                ><v-icon class="pe-2">$Pen</v-icon>ویرایش</v-btn
              >
            </div>
          </div>
        </v-card> -->
      </div>
    </div>
    <div v-if="isLoaded">
      <div
        :class="type == 'inbox' ? 'purple-footer' : 'blue-footer'"
        class="mt-3 pb-9"
        v-if="computedLetters.length"
      >
        <v-row class="ms-0 me-0">
          <v-col class="d-flex flex-row">
            <span class="pt-2">تعداد موارد نمایش در هر صفحه</span>
            <div
              :class="type == 'inbox' ? 'purple-number-box' : 'blue-number-box'"
              class="ms-3 me-3"
            >
              <v-select
                dense
                :menu-props="{ top: true, offsetY: true }"
                outlined
                v-model="perPage"
                :items="perPageOptions"
                @change="currentPage = 1"
              ></v-select>
            </div>
          </v-col>
          <v-col>
            <div
              :class="
                type == 'inbox'
                  ? 'purple-custom-pagination'
                  : 'blue-custom-pagination'
              "
              class="d-flex flex-row justify-content-end"
            >
              <v-btn @click="goToFirst()" class="navigation-btn"
                ><v-icon>$ArrowDoubleRight</v-icon></v-btn
              >
              <v-pagination
                v-model="currentPage"
                :length="
                  Math.ceil(
                    letters.filter((x) => x.letterNo.includes(search)).length /
                      perPage
                  )
                "
                :total-visible="5"
                prev-icon="$ArrowLeft"
                next-icon="$ArrowRight"
              ></v-pagination>
              <v-btn @click="goToLast()" class="navigation-btn"
                ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    Circle4,
  },
  props: [
    "letters",
    "arrivalLetters",
    "outputLetters",
    "type",
    "subtype",
    "isBusy",
    "isLoaded",
    "notSeen",
  ],
  data() {
    return {
      role: window.localStorage.role,
      search: "",
      panel: null,
      perPage: 4,
      currentPage: 1,
      perPageOptions: [4, 10, 20],
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(
        this.letters.filter((x) => x.letterNo.includes(this.search)).length /
          this.perPage
      );
    },
    goToNew() {
      this.$router.push(
        "/" + this.role + "/secretariat/" + this.type + "/newLetter"
      );
    },
    persianMonthName(time) {
      let date = time.split(" - ")[0];
      let hour = time.split(" - ")[1];
      let year = date.split("/")[0];
      let month = date.split("/")[1];
      let day = date.split("/")[2];
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return day + " " + monthName + " " + year + " - " + hour;
    },
    scrollToEnd() {
      // this function scroll the page to the bottom in every refreshing
      var container = document.querySelector("#container");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    },
    reply(id) {
      this.$router.push(
        "/" + this.role + "/secretariat/" + this.type + "/reply/" + id
      );
    },
    edit(id) {
      this.$router.push(
        "/" + this.role + "/secretariat/" + this.type + "/edit/" + id
      );
    },
  },

  computed: {
    computedLetters() {
      if (this.isLoaded) {
        let filtered;
        filtered = this.letters.filter((x) => x.letterNo.includes(this.search));
        filtered = filtered.slice(
          this.perPage * (this.currentPage - 1),
          this.perPage * this.currentPage
        );
        return filtered;
      }
    },
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.inbox-letters {
  .header {
    .row {
      background-color: #fff;
      border-radius: 30px;
      padding: 15px;
    }
  }
  .main-container {
    .letter-card {
      border-radius: 17px 6px 6px 17px;
      padding: 20px 20px 20px 25px;
      .letter-text {
        line-height: 28px;
      }
      &.purple-border {
        border-right: 7px solid #7e57c2;
      }
      &.blue-border {
        border-right: 7px solid #6092cd;
      }
      .purple-action {
        .v-btn {
          &:nth-child(2),
          &:nth-child(3) {
            path {
              stroke: #7e57c2;
            }
          }
        }
      }
      .blue-action {
        .v-btn {
          &:nth-child(2),
          &:nth-child(3) {
            path {
              stroke: #6092cd;
            }
          }
        }
        .edit-btn {
          path {
            stroke: #6092cd;
          }
        }
      }
    }
    .image-box {
      width: 70px;
      height: 70px;
      border-radius: 50px;
      .v-image {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
    }
    .v-expansion-panel-header__icon {
      display: none;
    }
    .v-expansion-panel,
    .v-expansion-panel-header {
      background-color: #fff !important;
      color: #313131 !important;
      border-radius: 15px 6px 6px 15px !important;
      margin-bottom: 15px;
      box-shadow: 0 !important;
      font-size: 0.9rem;
    }
    .v-expansion-panel {
      button:focus {
        background-color: #fff !important;
      }
    }
    .v-expansion-panels .v-expansion-panel:not(:first-child):after {
      display: none;
    }
    .v-expansion-panel:before {
      box-shadow: none !important;
    }
    .v-expansion-panel-header:before {
      background-color: #fff !important;
    }
  }
  .purple-border {
    &.v-expansion-panel {
      border-right: 6px solid #7e57c2 !important;
    }
  }
  .blue-border {
    &.v-expansion-panel {
      border-right: 6px solid #6092cd !important;
    }
  }
  .letter-borderBox {
    border: 1px dashed #ccc;
    border-radius: 10px;
    max-height: 90px;
    overflow: hidden;
    padding: 15px;
  }
  @mixin footer($color) {
    .row {
      background-color: #fff;
      border-radius: 30px;
      padding: 15px;
    }
    .top-btn {
      min-width: 33px !important;
      height: 17px !important;
      padding: 0 !important;
      border: 1px solid $color;
      border-radius: 4px 4px 0 0 !important;
      background-color: transparent !important;

      .v-icon {
        font-size: 18px !important;
      }
    }
    .down-btn {
      min-width: 33px !important;
      height: 17px !important;
      padding: 0 !important;
      border: 1px solid $color;
      border-top: 0;
      border-radius: 0 0 4px 4px !important;
      background-color: transparent !important;

      .v-icon {
        font-size: 18px !important;
      }
    }
    span {
      color: $color;
    }
  }
  .purple-footer {
    @include footer(#7e57c2);
  }
  .blue-footer {
    @include footer(#6092cd);
  }
  .blue-icon {
    stroke: #4c81c9;
  }
  .purple-icon {
    stroke: #7e57c2;
  }
  .blue-icon2 {
    svg {
      fill: #4c81c9;
    }
    path {
      stroke: #4c81c9;
    }
  }
  .purple-icon2 {
    svg {
      fill: #7e57c2;
    }
    path {
      stroke: #7e57c2;
    }
  }
}
</style>
