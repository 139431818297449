<template>
  <div class="internal-letters">
    <v-card class="text-center pa-10 cards" v-if="!isLoaded">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <circle-4></circle-4>
        <h6 class="mt-6">در حال بارگذاری ...</h6>
      </div>
    </v-card>
    <div v-else>
      <v-tabs
        v-if="$router.currentRoute.path == '/' + role + '/secretariat/internal'"
        fixed-tabs
        v-model="tab"
      >
        <v-tab class="me-1" @click="search = ''"
          ><v-icon class="px-2">$Inbox</v-icon>نامه‌های دریافت شده</v-tab
        >
        <v-tab class="ms-1" @click="search = ''"
          ><v-icon class="px-2">$Outbox</v-icon>نامه‌های ارسال شده</v-tab
        >
      </v-tabs>
      <v-tabs-items
        v-if="$router.currentRoute.path == '/' + role + '/secretariat/internal'"
        v-model="tab"
      >
        <v-tab-item>
          <v-card class="letter-card grey-card">
            <Letters
              v-if="
                $router.currentRoute.path ==
                '/' + role + '/secretariat/internal'
              "
              :letters="letters"
              :type="'internal'"
              :subtype="'received'"
              :isLoaded="isLoaded"
              :notSeen="notSeen"
              @save="save"
              @removeSave="removeSave"
              @archive="archive"
              @removeArchive="removeArchive"
              @viewLetter="viewLetter"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="letter-card grey-card">
            <Letters
              v-if="
                $router.currentRoute.path ==
                '/' + role + '/secretariat/internal'
              "
              :letters="letters"
              :type="'internal'"
              :subtype="'sent'"
              :isLoaded="isLoaded"
              @save="save"
              @archive="archive"
              @viewLetter="viewLetter"
              @removeSave="removeSave"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <router-view />
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import Letters from "../../../components/letters/Letters.vue";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    Letters,
    Circle4,
  },
  data() {
    return {
      tab: null,
      search: "",
      isLoaded: false,
      outputLetters: [],
      arrivalLetters: [],
      search: "",
      notSeen: "",
      role: "",
    };
  },
  methods: {
    viewLetter(id) {
      this.$router.push(
        "/" + this.role + "/secretariat/internal/letterInfo/" + id
      );
    },
    getLetters() {
      this.isBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/internalInbox",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.arrivalLetters = res.data.letters;
            this.notSeen = res.data.notSeen;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });

      // -----------------------------------------------------------------------------

      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/internalOutbox",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.outputLetters = res.data.letters;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    save(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/mark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeSave(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeMark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    archive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/archive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeArchive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeArchive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  computed: {
    letters() {
      if (this.tab == 0) {
        return this.arrivalLetters;
      } else if (this.tab == 1) {
        return this.outputLetters;
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getLetters();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../../assets/fonts/IRANSans(FaNum).ttf");
}
.internal-letters {
  .inbox-letters {
    padding: 0 !important;
  }
  .grey-card {
    &.v-card {
      background-color: #f6f7f9 !important;
    }
  }
  .v-window__container {
    background-color: #f6f7f9 !important;
  }
  .v-tabs-bar {
    background-color: #f6f7f9 !important;
    margin: 0 16px !important;
  }
  .v-tab {
    background-color: #fff;
    border-radius: 15px 15px 0 0 !important;
    max-width: none !important;
    font-size: 13px !important;
    font-weight: bold;
    path {
      stroke: #0000007a;
    }
    &:hover::before {
      border-radius: 15px 15px 0 0 !important;
    }
    &--active {
      background-color: #4c81c9;
      color: #00a7b7 !important;
      font-weight: normal;
      path {
        stroke: #00a7b7;
      }
    }
  }
}
</style>
